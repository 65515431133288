import styles from './Footer.module.scss'

const Footer = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.title}>FANDOM CREATOR</div>
                <div className={styles.address}>Suite 305, Griffith Corporate Centre, Beachmont,<br/>
                    Kingstown, St.Vincent and the Grenadines.
                </div>
                <div className={styles.email}>
                    Contact<span className={styles.kor}>(문의)</span> :&nbsp;
                    <a href="mailTo:support@cre8tor.io" target="_blank" rel="noopener">support@cre8tor.io</a>
                </div>
                <div className={styles.copyright}>ⓒ2023 FANDOM CREATOR</div>
            </div>
        </div>
    );
}

export default Footer;