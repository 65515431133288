import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_MORALIS_URL,
    headers: {
        'accept': 'application/json',
        'X-API-Key': process.env.REACT_APP_MORALIS_API_KEY,
    },
})

const getTokenTransfersByWalletWithBlockNumber = (contractAddress, walletAddress, chainId, limit, cursor) => {
    return instance.get(`/erc20/transfers?chain=${chainId}&contract_addresses[0]=${contractAddress}&wallet_addresses[0]=${walletAddress}&limit=${limit}&cursor=${cursor ? cursor : ''}`)
}

export {
    getTokenTransfersByWalletWithBlockNumber,
}
