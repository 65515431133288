import styles from './CustomModal.module.scss'

const CustomModal = ({content, clickBtn, btnContent, isModalOpen, closeModal}) => {
    return (
        <div className={`${styles.wrapper} ${isModalOpen && styles.active}`}>
            <div className={styles.container}>
                {content}
                <button className={`${styles.btnModal}`} onClick={clickBtn}>
                    {btnContent}
                </button>
            </div>
        </div>
    );
}

export default CustomModal;