import {getTokenTransfersByWalletWithBlockNumber} from "../apis/moralis";
import {CHAINS} from "../config/WagmiConfig";
const reduceAddress = (address) => {
    return address?.substring(0, 6) + '...' + address?.substring(address.length - 4)
}

const ALLOWED_CHAINS = process.env.REACT_APP_ALLOWED_CHAINS.split(',')
    .map(chainId => CHAINS.find(findChain => findChain.id === parseInt(chainId)))

const isAllowedChain = (chainId) => {
    return ALLOWED_CHAINS.some(chain => chain.id === chainId)
}

const isSameWallet = (walletAddress1, walletAddress2) => walletAddress1.toLowerCase() === walletAddress2.toLowerCase()

const getAllTransfersCrtr = async (contractAddress, walletAddress, chainId) => {
    const LIMIT = 100

    const txList = []
    let cursor = ''

    while (cursor !== null) {
        await getTokenTransfersByWalletWithBlockNumber(contractAddress, walletAddress, chainId, LIMIT, cursor)
            .then(({data}) => {
                txList.push(...data.result.filter(tx => isSameWallet(tx.to_wallet, walletAddress)))
                cursor = data.cursor
            })
            .catch(e => {
                console.log(e)
                cursor = null
            })
    }
    return txList
}

export {
    reduceAddress,
    isAllowedChain,
    getAllTransfersCrtr,
}