import styles from './App.module.scss'
import Crtr from "./crtr/Crtr";
import Loading from "./components/Loading";
import Footer from "./components/Footer";

const App = () => {
    return (
        <div className={styles.wrapper}>
            <Loading/>
            <Crtr/>
            <Footer/>
        </div>
    );
}

export default App;
