import {configureChains, createConfig} from "wagmi";
import {infuraProvider} from "wagmi/providers/infura";
import {publicProvider} from "wagmi/providers/public";

import {
    metaMaskWallet,
    walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import {connectorsForWallets} from "@rainbow-me/rainbowkit";
import {goerli, mainnet} from "wagmi/chains";

const CHAINS = [mainnet, goerli]

const WAGMI_CHAINS = process.env.REACT_APP_WAGMI_CHAINS.split(',')
    .map(chainId => CHAINS.find(findChain => findChain.id === parseInt(chainId)))

const {chains, publicClient} = configureChains(
    WAGMI_CHAINS,
    [infuraProvider({apiKey: process.env.REACT_APP_INFURA_PROJECT_ID}), publicProvider()]
)

const popularWallets = {
    groupName: 'Wallets',
    wallets: [
        metaMaskWallet({
            chains,
            projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID
        }),
        walletConnectWallet({
            chains,
            projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
            version: "2"
        }),
    ]
}

const connectors = connectorsForWallets([popularWallets])

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
})

export {
    CHAINS,
    chains,
    wagmiConfig,
}