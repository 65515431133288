import styles from './Loading.module.scss'
import {useRecoilValue} from "recoil";
import {isLoadingState} from "../states";
import {Spinner} from "../assets";
import {useEffect} from "react";

const Loading = () => {
    const isLoading = useRecoilValue(isLoadingState)

    useEffect(() => {
        if (isLoading) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'visible'
        }
    }, [isLoading]);

    return (
        <div className={`${styles.wrapper} ${isLoading && styles.active}`}>
            <img src={Spinner} alt="Spinner" className={styles.spinner}/>
        </div>
    );
}

export default Loading;