import '@rainbow-me/rainbowkit/styles.css'
import './style/fonts/fonts.css'
import './index.scss'

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {WagmiConfig} from "wagmi";
import {darkTheme, RainbowKitProvider} from "@rainbow-me/rainbowkit";
import {chains, wagmiConfig} from "./config/WagmiConfig";
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
            chains={chains}
            appInfo={{appName: "Fandom"}}
            theme={darkTheme({
                overlayBlur: "small",
                borderRadius: "large"
            })}
            modalSize="compact"
        >
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </RainbowKitProvider>
    </WagmiConfig>
);