import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
})

const putPersonalInfo = (address, name, phone) => {
    return instance.post('/', {address, name, phone})
}

const getPersonalInfo = (address) => {
    return instance.get(`/${address}`)
}

export {
    putPersonalInfo,
    getPersonalInfo,
}