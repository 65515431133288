import styles from './Crtr.module.scss'
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useEffect, useState} from "react";
import {useAccount, useNetwork, useSwitchNetwork} from "wagmi";
import {getAllTransfersCrtr, isAllowedChain, reduceAddress} from "../utils/utils";
import {mainnet} from "wagmi/chains";
import {
    BtnSubmitActive,
    BtnSubmitInactive,
    CbActive,
    CbInactive,
    ConnectWalletActive,
    ConnectWalletInactive, MoBtnSubmitActive, MoBtnSubmitInactive
} from "../assets";
import {COUNTRY_CODE} from '../utils/country_code.js';
import {getPersonalInfo, putPersonalInfo} from "../apis/crtr";
import CustomModal from "../components/CustomModal";
import {useSetRecoilState} from "recoil";
import {isLoadingState} from "../states";

const Crtr = () => {
    const INIT_COUNTRY_CODE = '+82'

    const [txList, setTxList] = useState([])
    const [name, setName] = useState('')
    const [countryCode, setCountryCode] = useState(INIT_COUNTRY_CODE)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isCbAgreeChecked, setIsCbAgreeChecked] = useState(false)
    const [isNetworkSwitchModalOpen, setIsNetworkSwitchModalOpen] = useState(false)
    const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)
    const setIsLoading = useSetRecoilState(isLoadingState)

    const openNetworkSwitchModal = () => {
        setIsNetworkSwitchModalOpen(true)
    }

    const closeNetworkSwitchModal = () => {
        setIsNetworkSwitchModalOpen(false)
    }

    const openCompleteModal = () => {
        setIsCompleteModalOpen(true)
    }

    const closeCompleteModal = () => {
        setIsCompleteModalOpen(false)
    }

    const {address} = useAccount()
    const {chain} = useNetwork()
    const {switchNetwork} = useSwitchNetwork({
        chainId: mainnet.id,
        onSuccess: closeNetworkSwitchModal,
    })

    const initStatus = () => {
        setIsCbAgreeChecked(false)
        setName('')
        setPhoneNumber('')
        setCountryCode(INIT_COUNTRY_CODE)
        setTxList([])
    }

    const startLoading = () => {
        setIsLoading(true)
    }
    const endLoading = () => {
        setIsLoading(false)
    }

    const modifyNumber = (number) => {
        const numbers = number.split('.')
        const integralPart = parseInt(numbers[0]).toLocaleString()
        const fractionalPart = numbers[1]
        if (parseInt(fractionalPart) === 0) {
            return integralPart
        } else {
            return integralPart + '.' + fractionalPart
        }
    }

    const parseDateToString = (date) =>
        `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`

    const changeCbAgree = (e) => {
        setIsCbAgreeChecked(e.target.checked)
    }

    const changeInputName = (e) => {
        const {value} = e.target || e.currentTarget
        setName(value)
    }

    const changeCountryCode = (e) => {
        const {value} = e.target || e.currentTarget
        setCountryCode(value)
    }

    const changeInputPhoneNumber = (e) => {
        const {value} = e.target || e.currentTarget
        setPhoneNumber(value)
    }

    const isSubmitDisabled = () => !isCbAgreeChecked || !name || !phoneNumber

    const clickSubmit = () => {
        if (isCbAgreeChecked && name && countryCode && phoneNumber) {
            startLoading()
            putPersonalInfo(address, name, `${countryCode})${phoneNumber}`)
                .then(() => {
                    openCompleteModal()
                })
                .catch(e => {
                    console.log(e)
                    alert('제출에 실패하였습니다.')
                })
                .finally(() => {
                    endLoading()
                })
        }
    }

    const getPersonalInfoAwait = async () => {
        const {data} = await getPersonalInfo(address)
        if (data) {
            setName(data.name)
            const phoneNumbers = data.phone.split(')')
            setCountryCode(phoneNumbers[0])
            setPhoneNumber(phoneNumbers[1])
        } else {
            initStatus()
        }
    }

    const getAllTransfersCrtrAwait = async () => {
        const CHAIN_ID = '0x' + chain.id.toString(16)
        const allTransfers = await getAllTransfersCrtr(process.env.REACT_APP_CRTR_CONTRACT_ADDRESS, address, CHAIN_ID)
        setTxList(allTransfers.map(tempTx => ({
            hash: tempTx.block_hash,
            timestamp: new Date(tempTx.block_timestamp),
            amount: parseFloat(tempTx.value_decimal).toFixed(2)
        })))
    }

    const getInfo = async () => {
        startLoading()
        try {
            await getPersonalInfoAwait()
            await getAllTransfersCrtrAwait()
        } catch (e) {
            console.log(e)
        } finally {
            endLoading()
        }
    }

    useEffect(() => {
        if (address && chain && switchNetwork) {
            if (isAllowedChain(chain.id)) {
                getInfo()
            } else {
                openNetworkSwitchModal()
            }
        } else {
            initStatus()
        }
    }, [address, chain, switchNetwork])

    useEffect(() => {
        if (name || phoneNumber) {
            setIsCbAgreeChecked(true)
        }
    }, [name, phoneNumber]);

    useEffect(() => {
        if (isNetworkSwitchModalOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'visible'
        }
    }, [isNetworkSwitchModalOpen]);


    return (
        <div className={styles.wrapper}>
            <div className={styles.notificationTitle}>Notification</div>
            <div className={styles.notification}>
                본 거래와 관련된 개인정보는 수집 이외의 어떠한 용도로도 사용되지 않음을 알려드립니다. <br/>
                <u> 본 거래 관련 정보수집 이후에는 더이상 거래를 하여선 안됩니다.<br/>
                    만약, 본 정보수집 이후에도 거래를 진행하는 경우 본 재단은 어떠한 책임도 질 수 없음을 알려드립니다.</u>
            </div>
            <ConnectButton.Custom>
                {({openConnectModal, openAccountModal}) => (
                    <div className={`${styles.btnConnectWalletWrapper} ${styles.borderGradient}`}>
                        <div className={styles.btnConnectWallet}
                             onClick={address ? openAccountModal : openConnectModal}>
                            <img src={address ? ConnectWalletActive : ConnectWalletInactive} alt="Connect Wallet"
                                 className={styles.imgConnectWallet}/>
                            {address ? '지갑 연동 완료' : '지갑 연결하기*'}
                        </div>
                    </div>
                )}
            </ConnectButton.Custom>
            <div className={`${styles.agreeWrapper} ${styles.borderGradient}`}>
                <div className={styles.agreeContainer}>
                    <div className={`${styles.agreeContent} ${(!address || !isCbAgreeChecked) && styles.disabled}`}>
                        <input id="cb-agree" className={styles.inputAgree} type="checkbox" onChange={changeCbAgree}
                               disabled={!address}/>
                        <label htmlFor="cb-agree" className={styles.labelAgree}>
                            <img src={isCbAgreeChecked ? CbActive : CbInactive} alt="Chekcbox"
                                 className={`${styles.imgAgree} ${!address && styles.disabled}`}/>
                            개인정보 처리방침에 동의합니다.
                        </label>
                    </div>
                </div>
            </div>
            <div className={`${styles.txListWrapper} ${styles.borderGradient}`}>
                <div className={styles.txListContainer}>
                    <div className={styles.title}>매입 내역</div>
                    <div className={styles.informationWrapper}>
                        <div className={styles.informationContainer}>
                            <div className={styles.informationTitle}>
                                지갑 주소
                            </div>
                            <input
                                className={`${styles.inputInformation} ${styles.inputAddress} ${!address && styles.disabled}`}
                                value={address ? reduceAddress(address) : ''} placeholder="Wallet Address" disabled/>
                        </div>
                        <div className={styles.informationContainer}>
                            <div className={styles.informationTitle}>
                                이름*
                            </div>
                            <input className={`${styles.inputInformation} ${styles.inputName}`} value={name}
                                   onChange={changeInputName} placeholder="Name" disabled={!isCbAgreeChecked}/>
                        </div>
                        <div className={styles.informationContainer}>
                            <div className={styles.informationTitle}>
                                연락처*
                            </div>
                            <div className={styles.contactWrapper}>
                                <select className={styles.selectCountryCode} disabled={!isCbAgreeChecked}
                                        value={countryCode} onChange={changeCountryCode}>
                                    {COUNTRY_CODE.map((code, index) => (
                                        <option key={index} value={code}>{code}</option>
                                    ))}
                                </select>
                                <input type="number" className={`${styles.inputInformation} ${styles.inputContact}`}
                                       onChange={changeInputPhoneNumber} placeholder="Phone Number" value={phoneNumber}
                                       disabled={!isCbAgreeChecked}/>
                            </div>
                        </div>
                        <img src={isSubmitDisabled() ? BtnSubmitInactive : BtnSubmitActive} alt="Submit"
                             className={styles.btnSubmit} onClick={clickSubmit}/>
                        <img src={isSubmitDisabled() ? MoBtnSubmitInactive : MoBtnSubmitActive} alt="Submit"
                             className={styles.moBtnSubmit} onClick={clickSubmit}/>
                    </div>
                    {!!txList.length && <div className={styles.txList}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>No.</div>
                            <div className={styles.title}>매입 일시</div>
                            <div className={styles.title}>매입 수량</div>
                        </div>
                        <div className={styles.twWrapper}>
                            {txList.map((tx, index) => (
                                <div key={index} className={styles.tx}>
                                    <div className={styles.number}>{index + 1}</div>
                                    <div className={styles.date}>{parseDateToString(tx.timestamp)}</div>
                                    <div className={styles.amount}>{modifyNumber(tx.amount)}</div>
                                </div>
                            ))}
                        </div>
                    </div>}
                </div>
            </div>
            <CustomModal
                content={(<>이더리움 네트워크로<br/>변경이 필요합니다.</>)}
                isModalOpen={isNetworkSwitchModalOpen}
                clickBtn={() => switchNetwork()}
                btnContent={'Switch'}
            />
            <CustomModal
                content={(<>제출되었습니다.</>)}
                isModalOpen={isCompleteModalOpen}
                clickBtn={closeCompleteModal}
                btnContent={'Close'}
            />
        </div>
    );
}

export default Crtr;